import React from "react";
import styled from "styled-components";

import vwcImg from '../assets/imgs/aboutImage.png';
import doc from '../assets/gis-resume-vcunningham.pdf';

const ProjectItemStyles = styled.div`
	@media only screen and (max-width: 800px){
		text-align:center;
  	}
	  img{
		  width: 600px;
		  margin-left: -5%;
		  margin-top: 22%;
	  }
	h1{
	width: 600px;
	margin-left: -35px;
	}
`

function About() {
  return (
	<ProjectItemStyles>
    <div className="about">
      <div className="fluid-container">
        <div className="row align-items-center my-5">
          <div className="col-lg-7">
            <img
              className="img-fluid rounded mb-4 mb-lg-0"
              src={vwcImg}
              alt="Vincent Cunningham"
            />
          </div>
          <div className="col-lg-5">
            <h1>Hi, I'm Violet.</h1>
            <h4 className="font-weight-light">Developer and Cartographer</h4>
            <br />
            <p>
			  I've created this website from scratch myself! With some help from React and Typescript. I enjoy the challenge of learning new technologies and wanted to create a showcase of my professional experience, but with my own personal touch and passions included.
			</p>
			<p>
              I've been programming since 2014 and am always looking to explore new applications of my knowledge.
              In recent years I've been exploring GIS and cartography applications and building various web-based projects and maps.
              Many of my projects use a combination of Python for back-end data collection and analysis, and Javascript webdev for creating interactive explorations of data or to showcase the projects here.
            </p>
            <p>
              In my free time I enjoy reading fantasy, listening to music, and working on various projects, both analog and digital.
              Some of them even make their way to this site!
            </p>

            <p>
			  Reference my resume below and
              get in touch at <a href="mailto:vcunningham1023@gmail.com">vcunningham1023@gmail.com</a>!
            </p>

            
          </div>
        </div>
        <div className="col-12">
          	<h2><b>Work Experience</b></h2>
			  <p><b>GIS Python Developer</b></p>
				  <p>USPS - Contracted through ECS Tech</p>
				  <ul>
					  <li>Independently working on geospatial automation needs for various projects. Often includes Python for data collection and API interactions followed by Python or Oracle connections for web layer updates</li>
					  <li>Python automation of tasks to reduce required time and to reduce the manual effort required</li>
				  	<li>Assist with data QC through automation to check Big Data collections and exports</li>
					  <li>March 2022 - Present</li>
				  </ul>
				  <p><b>Data Extraction Analyst</b></p>
				  <p>Cyclomedia</p>
				  <ul>
					  <li>Working in a full pointcloud environment to extract desired features for clients</li>
					  <li>Perform QA on manual and automatic LiDAR extractions</li>
				  	<li>Manipulate a geospatial database for automation and QA</li>
					  <li>August 2021-December 2021</li>
				  </ul>
				  <p><b>Student Developer</b></p>
			  		<p>UW-Cartography Lab</p>
				  <ul>
				  	<li>Worked independently on cartography and GIS projects</li>
				  	<li>Web-based development using Leaflet and D3</li>
				  	<li>Used Adobe CC and ArcMap</li>
				  	<li>June 2020-May 2021</li>
				  </ul>

			<h2><b>Technical Skills</b></h2>
				  <p>Python | Flask | React/Bootstrap | Javascript(HTML/CSS) | C# | C | Java</p>
				  <p>Github | ESRI products: Pro, arcpy, Portal, etc | Photoshop | Leaflet/D3 | Illustrator | Unity/Blender</p>

			<h2><b>Education</b></h2>
				  <p><b>University of Wisconsin-Madison, 2021</b></p>
			  	  <p><b>Bachelor of Science, double major:</b></p>
				  <ul>
				  	<li>Cartography and GIS</li>
					  <li>Computer Science</li>
				  </ul>

				  <b>Relevant Courses</b>
				  <ul>
					  <li>Introduction to: Programming, Cartography, GIS, and Geocomputing</li>
					  <li>Database Management Systems: Design and Implementation</li>
					  <li>Data Structures</li>
					  <li>Algorithms</li>
				  	<li>Computer Graphics</li>
				  	<li>Machine Organization and Programming</li>
				  	<li>Artificial Intelligence</li>
				  	<li>Discrete Math</li>
			  		<li>Programming Languages and Compilers</li>
				  	<li>Virtual Reality</li>
				  	<li>Linear Algebra and Differential Equations</li>
				  	<li>Advanced Geovisualization and Interactive Cartography</li>
				  	<li>Geospatial Big Data and Analytics</li>
				  	<li>Graphic Design in Cartography</li>
				  	<li>Geospatial Technologies</li>
				  	<li>Geography Capstone and Undergraduate Thesis</li>
			  	</ul>

			  	<h2><b>Awards/Achievements</b></h2>
			  	<ul>
				  	<li>May 2020. Invited presentation as part of a group of four students on "Tambopata:
					  	Protecting Forests and Empowering Communities; an Interactive Web Mapping
					  	Exercise" in the 2020 Teaching and Learning Symposium, UW-Madison. [Cancelled due
					  	to COVID outbreak]
				  	</li>
				  	<li>May 2020. Nominated for Cross-campus International Studies Student Award for
					    "Tambopata: Protecting Forests and Empowering Communities; an Interactive Web Mapping Exercise"
					  </li>
			  	</ul>

			  	<a className="d-flex justify-content-center" href={doc} download="gis-resume-vcunningham.pdf">
				  	Download Resume as PDF
				  </a>
			    <br/><br/>
        </div>
      </div>
    </div>
	</ProjectItemStyles>
  );
}

export default About;