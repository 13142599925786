import React, { useEffect } from "react";
import styled from "styled-components";

import { collection, getDocs} from 'firebase/firestore/lite';

const ProjectItemStyles = styled.div`
	@media only screen and (max-width: 800px){
		text-align:center;
  	}

	h1{
	  padding: 10px;
	  margin-bottom: 0px;
	}

	img{
		margin-bottom: 15px;
		width: 180px;
		height: 276px;
	}

	#search{
		margin-right: 5px;
	}
	
	.option-wrap{
		margin-bottom: 10px;
	}

	button{
		margin-right: 5px;
	}

	#body{
		margin-left: 15px;
	}

	.book{
		border-style: ridge;
		border-width: 5px;
		width: 300px;
		height: 400px;
		text-align: center;
		margin-right: 50px;
		display: inline-block;
		margin-bottom: 25px;
	}
`

function BookList(db) {
  db = db.db;

  useEffect(() => {
	loadBooks();
  })
  
  // Get a list of books in bookshelf
  async function loadBooks() {
	document.getElementById("list").innerHTML = "";
	const usersCol = collection(db, 'users', 'dev','Bookshelf');
	const userDoc = await getDocs(usersCol);
	const books = userDoc.docs.map(doc => doc.data());
	books.forEach(book => {
		document.getElementById("list").innerHTML+="<div class='book'><h3>"+book.title+"</h3>"+book.author+"<br /><img src='http://covers.openlibrary.org/b/isbn/"+book.isbn+"-M.jpg'><br /></div>";
	})
	return;
  }

  return (
	<ProjectItemStyles>

    <div id="body">
      <h1>Bookshelf</h1>
	  <br />
	  <div id="list">

	  </div>
    </div>
	</ProjectItemStyles>
  );
}

export default BookList;