import React from "react";
import styled from "styled-components";

import { collection, addDoc} from 'firebase/firestore/lite';

const ProjectItemStyles = styled.div`
	@media only screen and (max-width: 800px){
		text-align:center;
  	}

	h1{
	  padding: 10px;
	  margin-bottom: 0px;
	}

	img{
		margin-bottom: 20px;
	}

	#search{
		margin-right: 5px;
	}
	
	.option-wrap{
		margin-bottom: 10px;
	}

	button{
		margin-right: 5px;
	}

	#body{
		margin-left: 15px;
	}

	.book{
		border-style: ridge;
		border-width: 5px;
		width: 300px;
		height: 400px;
		text-align: center;
		margin-right: 50px;
		display: inline-block;
		margin-bottom: 25px;
	}
`

function wait(ms){
	var start = new Date().getTime();
	var end = start;
	while(end < start + ms) {
	  end = new Date().getTime();
   }
 }

async function getAuthor(id){
	let i = 0;
	while( i < 5){
		try{
			var author = "Unknown";
			console.log(id);
			fetch("https://openlibrary.org"+id+".json")
			.then(t => t.json)
			.then(response => {
				console.log(response)
				author = response.name;
			});
			return author;
		}catch{
			console.log("Author request failed")
			wait(5000);
			i++;
		}
	}
}

var user_isbn;
const userChoice = (isbn) => {
	user_isbn = isbn;

	document.getElementById("output").innerHTML="<h2>Selection added to bookshelf.</h2>";

	console.log("Choice made...")
}

async function chooseBook(title,author,isbns){
	try{
		let i = 0;
		while(i < 5){
			document.getElementById("output").innerHTML+="<div class='book' id='choice"+i+"' onClick={()=>userChoice(+"+isbns[i]+")}><h2>"+title+"</h2>"+author+"<br/><img src='http://covers.openlibrary.org/b/isbn/"+isbns[i]+"-M.jpg'></div>";
			i++;
		}
	}catch{
		console.log("Cover choices failed. Book didn't load.")
	}
}

async function getCover(title,author,isbns){
	try{
		let i = 0;
		while(i < 5){
			document.getElementById("output").innerHTML+="<div id='choice"+i+"' onClick='setChoice("+isbns[i]+")'><h2>"+title+"</h2>"+author+"<br /><img src='"+isbns[i]+"'><br /></div>";
			i++;
		}
	}catch{

	}
	return "http://covers.openlibrary.org/b/isbn/"+isbns[0]+"-M.jpg";
}

async function getBooks(){
	document.getElementById("output").innerHTML="";
	var userInput = document.getElementById("search").value;
	// If user inputs an ISBN query the exact book and cover
	// 9781250318541
	var isbns;
	if(userInput.length === 0){return}
	if(!isNaN(userInput)){
		try{
			var title;
			var isbn = userInput;
			var authors;
			await fetch("https://openlibrary.org/isbn/"+userInput+".json")
			.then(t => t.json())
			.then(response => {
				title = response.title;
				if(response.isbn_13 == null){
					isbn = response.isbn_10[0];
				}
				else{isbn = response.isbn_13[0];}
				authors = response.authors;
				isbns = response.isbn[13];
			});
			try{
				var author;
				await fetch("https://openlibrary.org"+authors[0]["key"]+".json")
				.then(t => t.json())
				.then(response => {
					author = response.name;
				});
			}catch{
				author = "Unknown";
			}
		}catch{
			console.log("Request failed.")
		}

		document.getElementById("output").innerHTML+="<h2>"+title+"</h2>"+author+"<br /><img src='"+getCover(title,author,isbns)+"'><br />";

		save(title, author, isbn);
	/*
	If user is searching, query the first book,
	allow for cover change or reselection later
	*/
	}else{
		fetch("http://openlibrary.org/search.json?q="+userInput)
		.then(t => t.json())
		.then(response => {
				try{
					console.log(response.docs[0])
					chooseBook(
						response.docs[0].title,
						response.docs[0].author_name[0],
						response.docs[0].isbn);
				}
				catch{
					console.log("Title skipped due to missing API data")
				}
		});
	}

	async function save(title, author, isbn){
		try {
			const docRef = await addDoc(collection(db_t, 'users', 'dev','Bookshelf'), {
			  author: author,
			  isbn: isbn,
			  title: title
			});
			console.log("Document written with ID: ", docRef.id);
		  } catch (e) {
			console.error("Error adding document: ", e);
		  }
	}
}

var db_t;
function BookSearch(db) {
  db_t = db.db;
  return (
	<ProjectItemStyles>

    <div id="body">
      <h1>Add new book</h1>
	  <div>
			<input id="search" />
	  		<button onClick={getBooks}>Search by keyword</button>
	  		<br />
			  <p>
				ISBN preferred, but title keyword search is supported,
				<br />
				just with less control over cover image/edition selected.
	  		  </p>
			<br />

	  		<div id="output" />
			  
		</div>
    </div>
	</ProjectItemStyles>
  );
}

export default BookSearch;