import { useRef, useEffect } from 'react'

const useCanvas = draw => {
  
  const canvasRef = useRef(null)
  
  useEffect(() => {
    
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')
    let frameCount = 0
    let animationFrameId

    canvas.width = window.innerWidth - 80
    canvas.height = window.innerHeight - 152
    context.scale(1,-1)
    context.translate(0,-context.canvas.height)
    
    const render = () => {
      context.clearRect(0, 0, context.width, context.height)
      frameCount++
      draw(context, frameCount)
      animationFrameId = window.requestAnimationFrame(render)
    }
    render()
    
    return () => {
      window.cancelAnimationFrame(animationFrameId)
    }
  }, [draw])
  
  return canvasRef
}

export default useCanvas