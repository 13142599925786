import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import {
  Navigation,
  Home,
  About,
  Books,
  Game
} from "./components";

import {
  G573,
  Energy,
  Ptolemy,
  /* Cartogram, */
  Uwcl,
  Vrgarden,
  Osrsbot,
} from "./components"

ReactDOM.render(
  
  <Router>
    <Navigation />
    <Routes>

      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/books" element={<Books />} />
      <Route path="/game" element={<Game />} />

      <Route path="/projects/G573" element={<G573 />} />
      <Route path="/projects/Energy" element={<Energy />} />
      <Route path="/projects/Ptolemy" element={<Ptolemy />} />
      <Route path="/projects/Vrgarden" element={<Vrgarden />} />
      <Route path="/projects/Osrsbot" element={<Osrsbot />} />
      <Route path="/projects/Uwcl" element={<Uwcl />} />


    </Routes>
  </Router>,

  document.getElementById("root")
);
