import React from "react";
import styled from "styled-components";

import Canvas from './Canvas'
import candyman_map from '../assets/imgs/fatal_federation/candyman.png';
import bg_img_src from "../assets/imgs/fatal_federation/background.png"
import speed_img_src from "../assets/imgs//fatal_federation/speed.png"

const ProjectItemStyles = styled.div`
html,body {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

p{
    padding: 0px;
    margin-bottom: -30px;
    margin-left: 10px;
}

#draw_collision_box{
    margin-top: 10px;
    margin-left: 20%;
}

canvas{
    margin: 40px;
    margin-top: 0px;
}
`

function Game() {
    var start = true
    var drawCollision = false
    var bg_img = new Image()
    var speed_img = new Image()

    var fps;
    function calcFPS() {
        this.step = function(frameCount){
            if(!this.lastCalledTime) {
                this.lastCalledTime = performance.now();
                this.fps = 0;
                return;
            }
            this.delta = (performance.now() - this.lastCalledTime)/1000;
            this.lastCalledTime = performance.now();
            this.fps = 1/this.delta;
            
            if(Number.isInteger(frameCount/5)){
                document.getElementById("fps").innerText = "FPS: " + Math.round(this.fps,2)
                this.debugText()
            }
        }

        this.debugText = function(){
            document.getElementById("fps").innerText+=" coords: "+Math.round(player1.x,2)+","+Math.round(player1.y,2)+"   "+Math.round(ball.getX(),2)+","+Math.round(ball.getY(),2) + "    " + player1.speedX         
        }
    } 

    //for (let i = 0; i < 37; i++){
        //candyman.append(candyman_map)
    //}

    var ball;
    function ballObj(ctx,x,y,speed) {
        // Inits
        var rad = 13

        this.speedX = speed;
        this.speedY = speed;
        this.dir = 0;
        this.color = "black"
        this.hits = 0
        this.hit_coef = 1.25

        this.update = function(ctx, frameCount){
            // Check for wall collision
            if(x > ctx.canvas.width-rad || x < rad){
                this.x += -this.speedX
                this.collide('border-x')
            }
            if(y > ctx.canvas.height-rad || y < rad){
                this.Y += -this.speedY

                this.collide('border-y')
            }

            // Update pos vars
            x += this.speedX
            y += this.speedY

            this.drawBall()
        }
        // Draw ball at current XY with the same styling
        this.drawBall = function(){
            ctx.strokeStyle = this.color
            ctx.lineWidth = 4
            ctx.fillStyle = 'white'
            ctx.beginPath()
            ctx.arc(x, y, rad, 0, 2*Math.PI)
            ctx.fill()
            ctx.stroke()
        }
        this.getX = function(){
            return x;
        }
        this.getY = function(){
            return y;
        }
        this.getSpeedX = function(){
            return this.speedX;
        }
        this.getSpeedY = function(){
            return this.speedY;
        }
        this.getDir = function(){
            return this.dir;
        }

        this.drawBall(ctx)

        // Collision handling
        // maybe make the ball hitbox slightly larger than drawn?
        this.collide = function(impact){
            // Sides
            if(impact === "kill"){
                player1.dead = true
            }
            else if(impact === 'border-x'){
                this.speedX = -1 * this.speedX
            }
            // Top/Bottom
            else if(impact === 'border-y'){
                this.speedY = -1 * this.speedY
            }

            // Player 
            else if(impact === "hit"){
                this.hits++

                this.speed = [this.speedX,this.speedY]

                this.speed[0] *= this.hit_coef*(this.hits/15+1)
                this.speed[1] *= this.hit_coef*(this.hits/15+1)

                this.speed[0] *= controls.axes_dir[0]
                this.speed[1] *= controls.axes_dir[1]

                this.speedX = 0
                this.speedY = 0
            }
            else if(impact === "bunt"){
                this.bunted = true

                this.speedX = 0
                this.speedY = 0
            }

            return
        }
        this.resume = function(){
            // read controller left axis for direction coef
            this.speedX = this.speed[0]
            this.speedY = this.speed[1]
            this.speed = []

            if(this.speedX === 0 && this.speedY === 0){
                this.speedX = 8
                this.speedY = 8
            }

            this.x += this.speedX
            this.y += this.speedY
            this.drawBall()
        }
      }

    var player1;
    function player(ctx,x,y,dir,id){
        // For pos and gravity
        this.x = x
        this.y = y
        this.speedX = 0
        this.speedY = 12

        // right=1, left=-1
        this.dir = dir

        // For multiplayer
        this.id = id
        this.color = "red"

        // For sprite sheet
        this.offsetX = 0
        this.offsetY = 0

        // For animation
        this.crouched = false
        this.map = candyman_map
        this.img = new Image(170,170)

        this.anim_tick = 0
        this.falling = false
        this.jumping = false
        this.swinging = false
        this.hitting = false
        this.dead = false

        this.update = function(ctx,frameCount){

            // clamp x movement more when speedY != 0
            if(!this.hitting){
                this.x = Math.min(Math.max(this.x + this.speedX, 42.5), ctx.canvas.width );
                this.y = Math.min(Math.max(this.y + this.speedY, 0), ctx.canvas.height);
            }

            if(this.speedX > 0){
                this.dir = 1
            }else if(this.speedX < 0){
                this.dir = -1
            }

            this.drawPlayer(ctx)
        }
        this.drawPlayer = function(){
            ctx.save()

            if(this.y <= 0){
                this.speedY = 0
                this.falling = false
            }

            if(this.swinging){
                this.chooseSprite("swing")
            }
            else if(this.y > 0){
                if(this.speedY > 0){
                    this.chooseSprite("fall")
                    this.falling = true
                    this.jumping = false
                }else{
                    this.chooseSprite("jump")
                    this.jumping = true
                }
            }
            else if(this.crouched){
                this.chooseSprite("crouch")
            }
            else if(this.speedX !== 0){
                this.chooseSprite("walk")
            }
            else{
                this.chooseSprite("")
            }

            if(this.y > 0){
                if(this.jumping === true){
                    this.speedY = this.speedY - 1.2
                }
                else if(this.falling === true){
                    this.speedY = this.speedY - 3
                }
            }

            if(this.dead){
                this.chooseSprite("dead")
            }

            // if hitting pause the speeds until done
            this.img.src = this.map
            ctx.save()

            ctx.scale(1,-1)
            ctx.translate(0,-168)

            if(this.dir === -1){
                ctx.translate(ctx.canvas.width,0)
                ctx.scale(-1,1)

                ctx.drawImage(this.img, this.offsetX, this.offsetY, 170, 170, ctx.canvas.width-(this.x+85), -this.y, 170, 170)
            }else{
                ctx.drawImage(this.img, this.offsetX, this.offsetY, 170, 170, this.x - 85, -this.y, 170, 170)
            }

            ctx.restore()

            ctx.strokeStyle = this.color;
            ctx.lineWidth = 3;

            let bbox = new Path2D()
            bbox.rect(this.x-85, this.y, 170, 170)
            if(drawCollision){
                ctx.stroke(bbox);
    
                let ball_box = new Path2D()
                ball_box.rect(ball.getX()-6.5, ball.getY()-6.5, 13, 13)
                ctx.stroke(ball_box);

                if(ctx.isPointInPath(bbox,ball.getX(),ctx.canvas.height-ball.getY()+13)){
                    this.collide("ball")
                    this.color = "green"
                }else if(this.color === "green"){
                    this.color = "red"
                }
            }else if(ctx.isPointInPath(bbox,ball.getX(),ctx.canvas.height-ball.getY()+13)){
                this.collide("ball")
            }

            ctx.restore()
            
        }

        this.hit = function(){
            this.hitting = true
            ball.collide("hit")
        }
        this.bunt = function(){
            this.bunting = true
            ball.collide("bunt")
        }
        this.swing = function(){
            this.swinging = true
        }
        this.gotHit = function(){
            ball.collide("kill")
        }

        // set spritesheet cell x/y for offset
        this.chooseSprite = function(sprite){
            switch(sprite) {
                case "dead":
                    x = 3
                    y = 5
                    break
                case "walk":
                    if(this.speedX < 10 && this.speedX > -10){
                        x = 0
                        y = 0
                    }else{
                        y = 3
                        x = 1
                    }

                    if(this.anim_tick > 7 && this.anim_tick < 15){
                        if(this.speedX > 10 || this.speedX < -10){
                            y = 3
                            x = 0
                        }else{
                            x = 2
                        }
                    }
                    else if(this.anim_tick >= 15){
                        if(this.speedX > 10 || this.speedX < -10){
                            y = 3
                        }
                        x = 1
                    }
                    if(this.anim_tick === 24){
                        this.anim_tick = 0
                        y = 3
                        x = 0
                    }

                    this.anim_tick = this.anim_tick + 1
                    break

                case "fall":
                    x = 4
                    y = 0
                    this.falling = true

                    break
                
                case "jump":
                    x = 3
                    y = 0
                    this.jumping = true

                    break

                case "swing":
                    x = 5
                    y = 0

                    let buffer = 8
                    let p1 = 2
                    let p2 = 3
                    let p3 = 4.7

                    // start: 5,0 -> 1,0 

                    // hit:   either 1,1 -> 1,3 
                    //            or 1,2 -> 1,3

                    // miss:  1,2 -> 1,4

                    if(this.anim_tick > buffer){
                        x = 0
                        y = 1
                    }
                    
                    // hit or miss diverge
                    if(this.anim_tick >= buffer*p1 && this.anim_tick < buffer*p2){
                        if(this.hitting){
                            x = 1
                            if(this.anim_buffer === false){
                                this.anim_tick = buffer*p1
                                this.anim_buffer = true
                            }
                        }else{
                            x = 2
                        }
                    }
                    else if(this.anim_tick >= buffer*p2 && this.anim_tick < buffer*p3){
                        if(this.hitting){
                            x = 3
                            if(this.anim_buffer === false){
                                this.anim_tick = buffer*p2
                                this.anim_buffer = true
                            }
                        }else{
                            x = 4
                        }
                    }
                    if(this.anim_tick >= buffer*p3){
                        if(this.hitting){
                            this.hitting = false
                            ball.resume()
                        }
                        this.swinging = false
                        this.anim_buffer = false
                        this.anim_tick = 0
                    }

                    this.anim_tick = this.anim_tick + 1
                    break

                default:
                    x = 0
                    y = 5

                    break
              } 
            
            this.offsetX = 170*x
            this.offsetY = 170*y

            return
        }
        this.getFramesCrouched = function(frameNum){
            // replace with crouched frame state logic


            return this.frames[frameNum]
        }
        this.getFramesJumped = function(frameNum){
            // replace with jumping frame state logic


            return this.frames[frameNum]
        }

        this.setColor = function(color){
            this.color = color
        }
        this.getColor = function(){
            return this.color
        }

        this.collide = function(impact){
            // hit by ball
            if(impact === "ball"){
                if(this.swinging && !this.hitting){
                    this.hit()
                }else if(!ball.bunted && (ball.speedX > 0 && ball.speedY > 0)){
                    this.gotHit()
                }
            }

            // hit ball with swing

            // wall jumps?
        }

        this.update(ctx,0)
    }

    let controls = 0
    function controller(){
        this.map = {
            "a" : 0, 
            "b" : 1, 
            "x" : 2, 
            "y" : 3, 
            "lb" : 4, 
            "rb" : 5, 
            "lt" : 6, 
            "rt" : 7, 
            "select" : 8,
            "start" : 9, 
            "l3" : 10,
            "r3" : 11, 
            "dpadUp" : 12,
            "dpadDown" : 13,
            "dpadLeft" : 14,
            "dpadRight" : 15
        }

        this.gamepad = []
        // [l-l/r, l-u/d, r-l/r, r-u/d]
        this.axes = []
        this.axes_dir = []
        this.connected = false

        this.switchControls = function(device){
            console.log("Switching to " + device)
        }

        this.update = function(){
            if(this.connected){
                this.gamepad.buttons.forEach((button, i) => {
              
                    if (typeof button === "object") {
                        if(button.value > 0){
                            let key = Object.keys(this.map).find(key => this.map[key] === i);
                            //console.log(key + " has been pressed.")

                            if(key === "a"){
                                if(player1.speedY === 0){
                                    player1.speedY = 22
                                }
                            }

                            if(key === "b"){
                                player1.bunt()
                            }

                            if(key === "x"){
                                player1.swing()
                            }
                        }
                    }
                });

                // TODO: track left axis for hit redirection
                this.gamepad.axes.forEach((axis, i) => {
                    if(i === 0){
                        //console.log(`${i}: ${axis.toFixed(4)}`)
                        if(Math.abs(axis.toFixed(2)) < 0.05){
                            player1.speedX = 0
                        } else{
                            player1.speedX = 12*axis.toFixed(2)
                        }
                    }

                    if(Math.abs(axis.toFixed(2)) < 0.1){
                        this.axes[i] = 0
                    } else{
                        this.axes[i] = axis.toFixed(2)
                    }

                    if(axis.toFixed(2) < 0){
                        this.axes_dir[i] = -1
                    }
                    else{
                        this.axes_dir[i] = 1
                    }
                  });
            }
        }

        this.gamepadHandler = function (event, connected) {
            this.connected = connected
          // Note:
          // gamepad === navigator.getGamepads()[gamepad.index]
        
            console.log("Handling gamepad connect/disconnect")

            if (connected) {
                this.gamepad = event.gamepad;
                this.switchControls("gamepad")
            } else {
                delete this.gamepad
                this.switchControls("mouse")
            }
        }
        
        window.addEventListener(
          "gamepadconnected",
          (e) => {
            this.gamepadHandler(e, true);
          },
          false
        );
        window.addEventListener(
          "gamepaddisconnected",
          (e) => {
            this.gamepadHandler(e, false);
          },
          false
        );

        function keydownHandler(event) {
            //console.log(event.code)
            if(this.connected === true){return}
            if(event.repeat){return}
            if (event.defaultPrevented) {
            return;
            }
            if (event.code === "KeyS"){
                // Add crouch
                // do we want a crouch?
                console.log("Pressed crouch")
            } else if (event.code === "KeyW"){
                // Add jump physics
                //       :)
                // keyframes 
            } else if (event.code === "KeyA"){
                // Handle left
                player1.speedX = player1.speedX - 5
            } else if (event.code === "KeyD"){
                // Handle right
                player1.speedX = player1.speedX + 5
            }
            event.preventDefault();
        }
        function keyupHandler(event) {
            if (this.connected === true){return}
            if (event.defaultPrevented) {
            return;
            }
            if (event.code === "KeyS"){
                // Add crouch
                console.log("Unpressed crouch")
            } else if (event.code === "KeyW"){
                // Add jump physics
                //       :)
            } else if (event.code === "KeyA"){
                // Handle left
                player1.speedX = player1.speedX + 5
            } else if (event.code === "KeyD"){
                // Handle right
                player1.speedX = player1.speedX - 5
            }
            event.preventDefault();
        }

        window.addEventListener("keydown", keydownHandler, true);
        window.addEventListener("keyup", keyupHandler, true);

        console.log("Controls loaded")
    }

    const draw = (ctx, frameCount) => {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)

        drawCollision = document.getElementById("draw_collision_box").checked

        if (start){
            fps = new calcFPS(0)

            ball = new ballObj(ctx,ctx.canvas.width/2,85, 0)
            player1 = new player(ctx, 250, 250, 1, 0)
            controls = new controller(ctx)

            bg_img.src = bg_img_src

            start = false
            canvasBG(ctx)
        }else{
            canvasBG(ctx)
            gameTick(ctx,frameCount)
        }
    }

    const gameTick = (ctx,frameCount) => {
        fps.step(frameCount)

        controls.update(ctx,frameCount)
        ball.update(ctx,frameCount)
        player1.update(ctx,frameCount)
    }

    const canvasBG = (ctx) => {
        ctx.save()
        ctx.strokeStyle = "black";
        ctx.lineWidth = 5;
        ctx.strokeRect(0, 0, ctx.canvas.width, ctx.canvas.height);

        ctx.save()
        ctx.scale(1,-1)

        ctx.drawImage(bg_img, 0,0, bg_img.width, bg_img.height, 0,0,ctx.canvas.width,-ctx.canvas.height);

        speed_img.src = speed_img_src
        ctx.drawImage(speed_img,ctx.canvas.width/2 - 300,-ctx.canvas.height, 350,100)

        ctx.font = "90px serif"
        ctx.fillStyle = "red"
        ctx.shadowColor = "black"
        ctx.shadowBlur = 2
        const text = ctx.measureText(ball.speedX)
        const ballSpeed = Math.abs(Math.round(ball.speedY/ball.speedX))
        ctx.fillText(ballSpeed, ctx.canvas.width/2 + 75,-ctx.canvas.height+75)
        ctx.lineWidth = 2
        ctx.strokeText(ballSpeed, ctx.canvas.width/2 + 75,-ctx.canvas.height+75)
        
        ctx.restore()


        if (drawCollision){
            ctx.strokeStyle = "red"
                ctx.lineWidth = 4
                ctx.fillStyle = 'red'
                ctx.beginPath()
                ctx.arc(0, 0, 10, 0, 2*Math.PI)
                ctx.fill()
                ctx.stroke()

            if(player1){
                ctx.strokeStyle = "blue"
                ctx.lineWidth = 4
                ctx.fillStyle = 'blue'
                ctx.beginPath()
                ctx.arc(player1.x, player1.y, 10, 0, 2*Math.PI)
                ctx.fill()
                ctx.stroke()
            }
        }
        
        ctx.restore()
    } 

    

  return (
	<ProjectItemStyles>

    <div id="body">
        <p id="fps">FPS</p>
        <input type="checkbox" id="draw_collision_box" name="draw_collision_box"/>
        <label for="draw_collision_box">Hitboxes</label>
        <Canvas draw={draw} tabIndex={0} />
    </div>

	</ProjectItemStyles>
  );
}

export default Game;