import React from "react";
import styled from "styled-components";

import { initializeApp } from 'firebase/app';
import { getFirestore} from 'firebase/firestore/lite';

import BookSearch from './BookSearch';
import BookList from './BookList'

const ProjectItemStyles = styled.div`
	@media only screen and (max-width: 800px){
		text-align:center;
  	}

	h1{
	  padding: 10px;
	  margin-bottom: 0px;
	}

	#search{
		margin-right: 5px;
	}
	
	.option-wrap{
		margin-bottom: 10px;
	}

	button{
		margin-right: 5px;
	}

	#body{
		margin-left: 15px;
	}
`

const firebaseConfig = {

	apiKey: "AIzaSyD5OBei6QKDOalyZHtGF1AavHdeQDAkv-g",
  
	authDomain: "portfoliositebooks.firebaseapp.com",
  
	projectId: "portfoliositebooks",
  
	storageBucket: "portfoliositebooks.appspot.com",
  
	messagingSenderId: "107103940864",
  
	appId: "1:107103940864:web:dcdf839109eba182f243e5"
  
  };
  
  
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function Books() {

  return (
	<ProjectItemStyles>

    <div id="body">
	  <BookList db={db} />
	  <BookSearch db={db}/>
    </div>
	</ProjectItemStyles>
  );
}

export default Books;